import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const TrackingHistory = Loadable({
  loader: () => import("./trackingHistory/TrackingHistory"),
  loading: Loading
});

const TravelHistory = Loadable({
  loader: () => import("./travelHistory/TravelHistory"),
  loading: Loading
});

const DistanceHistory = Loadable({
  loader: () => import("./distanceHistory/DistanceHistory"),
  loading: Loading
});

const StoppingHistory = Loadable({
  loader: () => import("./stoppingHistory/StoppingHistory"),
  loading: Loading
});

export const routes = [
  {
    path: "/historical/trackinghistory",
    exact: true,
    component: TrackingHistory,
    name: "Tracking History"
  },

  {
    path: "/historical/travelhistory",
    exact: true,
    component: TravelHistory,
    name: "Travel History"
  },

  {
    path: "/historical/distancehistory",
    exact: true,
    component: DistanceHistory,
    name: "Distance History"
  },

  {
    path: "/historical/stoppinghistory",
    exact: true,
    component: StoppingHistory,
    name: "Stopping History"
  },
];
